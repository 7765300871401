import React from "react";
import { Redirect } from "react-router-dom";

const PrivateRoute = (props) => {
  if (props.isAuthenticated == false) return <Redirect to="/login" />;
  return (
    <>
      <div className="private">{props.children}</div>
      {/* <Footer /> */}
    </>
  );
};

export default PrivateRoute;
