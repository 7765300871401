import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  configre: {
    cameraControls: {
      value: null,
      data: [],
    },
  },
  slider: {
    data: [],
  },
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setCameraControl: (state, action) => {
      state.configre.cameraControls = {
        ...state.configre.cameraControls,
        ...action.payload,
      };
    },
    setImagesFromCDN: (state, action) => {
      state.slider.data = action.payload;
    },
  },
});

export const fetchImagesFromCDN = createAsyncThunk(
  "admin/fetchImages",
  async ({ interiorColor, exteriorColor, trim, latestVersion }, thunkAPI) => {
    return fetch(
      `${process.env.REACT_APP_HMA_CDN_URL}&intcolor=${
        interiorColor ? interiorColor : ""
      }&extcolor=${exteriorColor}&fsc=${trim}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        thunkAPI.dispatch(
          uiSlice.actions.setImagesFromCDN(
            data.results.filter(
              (item) => parseFloat(item["latest_version"]) === latestVersion
            )
          )
        );
      })
      .catch((error) => {
        // handle error
        console.log("error", error);
      });
  }
);

const { actions, reducer } = uiSlice;
export const { setCameraControl } = actions;

export default reducer;
